<template>
  <div>
    <div class="flix-text-right flix-form-group">
      <a href="#" class="flix-btn flix-btn-default flix-btn-xs" @click.prevent="open = !open">{{ $t('message.edit', { name: $t('message.date') + ' / ' + $t('message.timeRange') }) }}</a>
    </div>
    <transition name="flixFadeIn">
      <div v-if="open" :key="updateKey">
        <dateForm :value="begin" :callback="function (ret) { setBegin(ret) }"/>
        <div>
          <div class="flix-form-group flix-text-right">
            <a href="#" class="flix-html-a" @click.prevent="function () { editEnd = !editEnd }">{{ $t('message.end') }}</a>:
            <a v-if="!editEnd" href="#" class="flix-html-a" @click.prevent="function () { editEnd = true }">
              <flixIcon :id="'calendar'" /> {{ $getNullsBefore(end[0][2] * 1) }}. {{ $t('constants.months')[end[0][1]] }} {{ $getNullsBefore(end[0][0] * 1) }} <flixIcon :id="'clock'" /> {{ $getNullsBefore(end[1][0] * 1) }}:{{ $getNullsBefore(end[1][1] * 1) }} {{ $t('message.oClock') }}
            </a>
          </div>
          <dateForm v-if="editEnd" :value="end" :callback="function (ret) { setEnd(ret) }"/>
          <div class="flix-form-group flix-text-right" v-if="saveBtn && !saveMsg">
            <check :id="entry.form" :date="getCheckDate()" :serial="false" />
          </div>
        </div>
        <div class="flix-form-group flix-text-right" v-if="saveBtn && !saveMsg">
          <saveBtn :onClick="function(){ setSave() }"></saveBtn>
        </div>
        <div class="flix-form-group flix-text-right" v-if="saveMsg">
          <transition name="flixFadeIn">
            <div class="flix-alert flix-alert-success" :key="saveMsg">{{ $t('message.successfullSaved') }}</div>
          </transition>
        </div>

      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    check () { return import('@/components/crm/addCustomer/check') },
    dateForm () { return import('@/components/booking/changeDate/form') }
  },
  props: {
    entry: Object,
    callback: Function
  },
  data () {
    return {
      open: false,
      begin: this.getBegin(),
      end: this.getEnd(),
      range: this.getRange(),
      updateKey: new Date().getTime(),
      saveBtn: false,
      saveMsg: false,
      editEnd: false
    }
  },
  watch: {
    'begin' () { this.getCheckBeginEnd() },
    'end' () { this.getCheckBeginEnd() }
  },
  methods: {
    getCheckBeginEnd () {
      var dates = this.getCheckDate()
      if (this.$createDate(dates.end).getTime() < this.$createDate(dates.begin).getTime()) {
        this.saveBtn = false
      } else if (this.$createDate(dates.end).getTime() < new Date().getTime()) {
        this.saveBtn = false
      } else {
        this.saveBtn = true
      }
    },
    getCheckDate () {
      return {
        begin: (this.begin[0][0] * 1) + '-' + (this.begin[0][1] * 1) + '-' + (this.begin[0][2] * 1) + ' ' + (this.begin[1][0] * 1) + ':' + (this.begin[1][1] * 1),
        end: (this.end[0][0] * 1) + '-' + (this.end[0][1] * 1) + '-' + (this.end[0][2] * 1) + ' ' + (this.end[1][0] * 1) + ':' + (this.end[1][1] * 1)
      }
    },
    setSave () {
      var variables = this.$getUserVariables()

      var save = {
        user: variables.user.md5_id,
        ID: this.entry.ID,
        begin: { Y: this.begin[0][0], m: this.begin[0][1] * 1, d: this.begin[0][2], H: this.begin[1][0] * 1, i: this.begin[1][1] * 1 },
        end: { Y: this.end[0][0], m: this.end[0][1] * 1, d: this.end[0][2], H: this.end[1][0] * 1, i: this.end[1][1] * 1 },
        admin: variables.user.md5_id,
        service: this.entry.service,
        event_id: this.entry.form
      }
      if (save.service === 'null' || save.service === null || isNaN(save.service)) {
        delete save.service
      }

      var newEntry = JSON.parse(JSON.stringify(this.entry))
      newEntry.begin = this.begin[0][0] + '-' + this.$getNullsBefore(this.begin[0][1]) + '-' + this.$getNullsBefore(this.begin[0][2]) + ' ' + this.$getNullsBefore(this.begin[1][0]) + ':' + this.$getNullsBefore(this.begin[1][1]) + ':00'
      newEntry.end = this.end[0][0] + '-' + this.$getNullsBefore(this.end[0][1]) + '-' + this.$getNullsBefore(this.end[0][2]) + ' ' + this.$getNullsBefore(this.end[1][0]) + ':' + this.$getNullsBefore(this.end[1][1]) + ':00'

      this.$flix_post({
        data: save,
        url: 'booking/change_date',
        callback: function (ret) {
          this.saveMsg = true
          newEntry.booking_date = ret.data[2]

          this.$store.dispatch('changeEntryDate', {
            ID: this.entry.ID,
            md5ID: this.entry.md5ID,
            form: this.entry.form,
            callback: function (a) {
              this.saveMsg = true
              setTimeout(function () {
                this.callback(a)
                this.saveMsg = false
              }.bind(this), 2000)
            }.bind(this)
          })
        }.bind(this)
      })
    },
    checkSave () {
      this.saveBtn = false
      var e = this.end
      var b = this.begin
      var begin = new Date(b[0][0], b[0][1] - 1, b[0][2], b[1][0], b[1][1], b[1][2])
      var end = new Date(e[0][0], e[0][1] - 1, e[0][2], e[1][0], e[1][1], e[1][2])

      if (begin.getTime() > new Date().getTime()) {
        this.saveBtn = true
      }
      if (end.getTime() < begin.getTime()) {
        this.saveBtn = false
      }
    },
    setBegin (ret) {
      this.begin = ret
      var begin = this.getCheckDate()
      begin = this.$createDate(begin.begin)
      if (!this.editEnd) {
        var end = new Date(begin.getTime() * 1 + this.range * 1)
        this.end = [[end.getFullYear(), end.getMonth() + 1, end.getDate()], [end.getHours(), end.getMinutes()]]
      }
      this.updateKey = new Date().getTime()
    },
    setEnd (ret) {
      this.end = ret
      this.updateKey = new Date().getTime()
    },
    getRange () {
      var b = this.getBegin()
      var e = this.getEnd()

      this.saveBtn = false
      var begin = new Date(b[0][0], b[0][1], b[0][2], b[1][0], b[1][1], b[1][2])
      var end = new Date(e[0][0], e[0][1], e[0][2], e[1][0], e[1][1], e[1][2])

      if (begin.getTime() > new Date().getTime()) {
        this.saveBtn = true
      }

      return end.getTime() - begin.getTime()
    },
    getBegin () {
      var begin = this.entry.begin.split(' ')
      begin[0] = begin[0].split('-')
      begin[1] = begin[1].split(':')
      begin[0][0] = begin[0][0] * 1
      begin[0][1] = begin[0][1] * 1
      begin[0][2] = begin[0][2] * 1
      return begin
    },
    getEnd () {
      var end = this.entry.end.split(' ')
      end[0] = end[0].split('-')
      end[1] = end[1].split(':')
      end[0][0] = end[0][0] * 1
      end[0][1] = end[0][1] * 1
      end[0][2] = end[0][2] * 1
      return end
    }
  },
  mounted () {
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>

</style>
